<template>
    <div>
        <div class="head-container">
            <div>
                <div class="head-text-main">
                    Advisory services
                </div>
            </div>
        </div>
        <v-container class="main-text text-container">
            <v-row>
                <v-col>
                    <span class="green-text">NUTRISENSE PC</span>, a spinoff company of the Agricultural University of Athens, provides high-quality consulting services for the management of nutrition and crop fertilization in vegetable and ornamental crops. The services offered by <span class="green-text">NUTRISENSE PC</span> are described below in more detail.
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <ol>
                        <li>
                            <strong>CHEMICAL ANALYSIS PACKAGES</strong>
                        </li>
                    </ol>
                    <p class="pl-4">
                        <span class="green-text">NUTRISENSE PC</span> provides to growers and professionals working in the sector of open-field and greenhouse horticulture, including soil and soilless cropping, a wide range of packages for chemical analysis of water, nutrient solutions, soil, and plant tissues. The chemical analyses of water, nutrient solutions, soil, and plant tissues are provided in collaboration and with the prestige of the Agricultural University of Athens.
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <ol start="2">
                        <li>
                            <strong>CONSULTANCY FOR OPTIMAL CROP FERTILIZATION</strong>
                        </li>
                    </ol>
                    <p class="pl-4">
                        <span class="green-text">NUTRISENSE PC</span> provides various consulting packages on soilless cropping, but also more generally on greenhouse cropping, as well as on open-field vegetable cropping. Our highly qualified agronomists undertake the task to design a full nutrition and fertilization program tailored to the specific needs of each horticultural enterprise for a full cropping season. This includes instructions for base-dressing in soil-grown crops, as well as for fertigation in both soil-grown and soilless crops using nutrient solutions with an optimal composition. The fertigation program is not fixed but regularly controlled and updated, based on chemical analyses of drainage solution samples in soilless crops, or of soil solution samples in soil-grown crops.
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    For further information, do not hesitate to contact us: <br>
                    Email: nutrisense@nutrisense.online <br>
                    Phone: <br> +30 694 836 8915 <br> +30 698 684 6648
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
        }),
        computed: {
        }
    }
</script>
<style scoped>
.heading {
    color: #5FA936;
    font-size: 36px;
    font-weight: 700;
}

.head-container{
    background-image: url("~@/assets/about_a_head.png");
    height: 380px;
    width: 100%;
    z-index: 0;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-shadow: 0px 0px 20px #0304036b;
    margin-top: -50px;
}

.head-text-main {
    font-weight: 700;
    font-size: 96px;
}

.head-text-secondary {
    font-size: 32px;
}

.main-text {
    color: #646464;
    font-size: 16px;
    line-height: 26px;
}
.text-container {
    max-width: 65vw;
}

.green-text {
    color: #006600;
    font-weight: bold;
}
</style>
